import React,{Component} from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
 
// ...
const containerStyle = {
  position: 'relative',  
  width: '90%',
  height: '40%',
  margin:'auto'
  // marginTop:'110px'
}
 //29.061278086990498, 76.95115633751318
export class MapContainer extends Component {
  
    render() {
      return (
        <Map 
        containerStyle={containerStyle}
        initialCenter={{
            lat: 29.061278086990498,
            lng: 76.95115633751318
          }}
        google={this.props.google} zoom={14}>
   
          <Marker onClick={this.onMarkerClick}
                  name={'Current location'} />
   
          <InfoWindow onClose={this.onInfoWindowClose}>
              {/* <div>
                <h1>{this.state.selectedPlace.name}</h1>
              </div> */}
          </InfoWindow>
        </Map>
      );
    }
  }
   
 
export default GoogleApiWrapper({
  apiKey: ('AIzaSyBG574AoRS3k5EnQKdvuPDeFivMhagxYGA')
})(MapContainer)
