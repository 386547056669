import React, { Component } from 'react'
import Navigation from './components/navigation';
import Header from './components/header';
import Features from './components/features';
import About from './components/about';
import Services from './components/services';
import Gallery from './components/gallery';
import Testimonials from './components/testimonials';
// import Team from './components/Team';
import Contact from './components/contact';
import Contact2 from './components/contact2';
import JsonData from './data/data.json';
import Service2 from './components/services2'
import Map from './components/Maps'
import Household from './pages/Household';
// import Avatar from './components/features2';
import './App.css'
import {BrowserRouter as Router,Switch,Route} from 'react-router-dom'
import { motion } from 'framer-motion';

export class App extends Component {
  state = {
    landingPageData: {},
  }
  getlandingPageData() {
    this.setState({landingPageData : JsonData})
  }

  componentDidMount() {
    this.getlandingPageData();
  }

  render() {
    return (
      <Router>
      <motion.div>
        
          <Switch> 
        <Route exact path='/'>
        <Navigation />
        <Header data={this.state.landingPageData.Header} />
        <Contact data={this.state.landingPageData.Contact} />
        {/* <Avatar></Avatar> */}
        <Features data={this.state.landingPageData.Features} />
        <About data={this.state.landingPageData.About} />
        <Services data={this.state.landingPageData.Services} />
        {/* <Service2/> */}
        <Gallery />
        {/* <Testimonials data={this.state.landingPageData.Testimonials} /> */}
        {/* <Team data={this.state.landingPageData.Team} /> */}
        <Contact2 data={this.state.landingPageData.Contact} />
        </Route>
        {/* <Route path='/services/householshifting'>
        <Navigation/>
            <Household data={this.state.landingPageData.Contact}/>
            </Route> */}
        <Route path='/Maps'>
        <Navigation />
               <Map/>
            </Route>
            
            <Route path='*'>
<h1>Oops page not found</h1>
      </Route>
      
        </Switch>
        
      </motion.div>
      </Router>
    )
  }
}

export default App;
