import React, { Component,useState,useEffect } from "react";
import Axios from 'axios'
import validateEmail from 'js-email-validator'
import validatePhoneNumber from 'validate-phone-number-node-js'
import Aos from "aos";

const Contact=(props)=>{
  const [name2,setName2]=useState()
  const [email,setEmail]=useState()
  const [to,setTo]=useState()
  const [from,setFrom]=useState()
  const [phone,setPhone]=useState()
  const [type,setType]=useState()
  const [message,setMessage]=useState()
  const [disabled,setDisabled]=useState(false)
  const [sent,setSent]=useState()

  useEffect(() => {
    Aos.init({
      duration:1000
    })
  }, [])

const nameHandler=(e)=>{
  e.preventDefault()
  setName2(e.target.value)

}
const emailHandler=(e)=>{
  setEmail(e.target.value)
  

}
const messageHandler=(e)=>{
  setMessage(e.target.value)

}
const toLocationHandler=(e)=>{
  setTo(e.target.value)

}
const fromLocationHandler=(e)=>{
  setFrom(e.target.value)

}
const phoneHandler=(e)=>{
  setPhone(e.target.value)
  
  
}
const typeHandler=(e)=>{
  setType(e.target.value)

}

  const func=(e)=>{
     setDisabled(true)
     e.preventDefault()
     const result=validateEmail(email)
     const result2 = validatePhoneNumber.validate(phone)
  console.log(result2)
   if(result===true&&result2===true){
    Axios.post("https://crown-cargo.herokuapp.com/api/emails",{
      "sender":email,
      "name":name2,
      "message":message,
      "phone":phone,
      "fromLocation":from,
      "toLocation":to,
      "type":type
    
    }).then(data=>{
      console.log(data)
      setSent(true)
      setDisabled(false)
      alert('sent')
      
     
    }).catch(e=>{
      setDisabled(false)
      setSent(true)
     alert('something went wrong try again')

      
    })
   }else{
     if(result!==true){
      alert('Please enter a valid email id')
     }
     if(result2!==true){
      alert('Please enter a valid mobile No.')
     }
     
     setSent(true)
     setDisabled(false)
   }
 
  
   }
    return (
      <div>
        <div id="contact">
          <div className="container">
            <div className="col-md-8">
              <div className="row">
                <div className="section-title">
                  <h2 data-aos="fade-right">Request A Quote</h2>
                  <a
        href="https://wa.me/+919711068222"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>
                  <p data-aos="fade-right">
                    Please fill out the form below to send us an email and we
                    will get back to you as soon as possible.
                  </p>
                </div>
                <form data-aos="fade-right" onSubmit={(e)=>{func(e)}} name="sentMessage" id="contactForm" noValidate>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                        onChange={(e)=>nameHandler(e)}
                          type="text"
                          id="name"
                          className="form-control"
                          placeholder="Name"
                          required="required"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                        onChange={(e)=>phoneHandler(e)}
                          type="text"
                          id="phone"
                          className="form-control"
                          placeholder="Phone No."
                          required="required"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                        onChange={(e)=>fromLocationHandler(e)}
                          type="text"
                          id="fromLocation"
                          className="form-control"
                          placeholder="From Location"
                          
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                        onChange={(e)=>toLocationHandler(e)}
                          type="text"
                          id="toLocation"
                          className="form-control"
                          placeholder="To location"
                          required="required"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                        onChange={(e)=>typeHandler(e)}
                          type="text"
                          id="type"
                          className="form-control"
                          placeholder="Type"
                          
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                         onChange={(e)=>emailHandler(e)}
                          type="email"
                          id="email"
                          className="form-control"
                          placeholder="Email"
                          required="required"
                          
                        />
                        
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                     onChange={(e)=>messageHandler(e)}
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Message"
                      required
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div id="success"></div>
                  <button type="submit" disabled={disabled} className="btn btn-custom btn-lg">
                    Send Message
                  </button>
                </form>
              </div>
            </div>
            <div data-aos="fade-right" className="col-md-3 col-md-offset-1 contact-info">
              <div className="contact-item">
                <h3>Contact Info</h3>
                
                <h4>Gurugram </h4>
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Address
                  </span>
                  {props.data ? props.data.address : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Phone
                  </span>{" "}
                  {/* <a href="tel:+917378018941">Call us</a> */}
                  {props.data ? props.data.phone : "loading"}
                </p>
              </div>
             
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  {props.data ? props.data.email : "loading"}
                </p>
              </div>

       <div className="contact-item">
              <h4>Banglore </h4>
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Address
                  </span>
                  {props.data ? props.data.address2 : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Phone
                  </span>{" "}
                  {/* <a href="tel:+917378018941">Call us</a> */}
                  {props.data ? props.data.phone2 : "loading"}
                </p>
              </div>
             
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  {props.data ? props.data.email2 : "loading"}
                </p>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    );
  }


export default Contact;
