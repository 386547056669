import React, { Component,useEffect } from "react";
import Aos from "aos";

const Contact=()=>{
   useEffect(() => {
   Aos.init({
     duration:1000
   })
   }, [])
    return (
      <div>
        <div id="contact" style={{width:'100vw'}}>
          <div className="container">
          
            <div className="col-md-12">
              <div data-aos="fade-right" className="row">
              <div className="addition">
              <div className="addition"> <a style={{display:"flex",flexDirection:"row",color:'white'}} href="https://www.payunow.com/crowncargopackersmovers"><i style={{fontSize:"25px",marginRight:'10px',marginTop:'8px'}} class="fa fa-money" aria-hidden="true"></i><h5>Pay Online</h5></a></div>
          <a style={{display:"flex",flexDirection:"row",marginRight:'15px',color:'white'}} href="tel:+919711068222"><i style={{fontSize:"25px",marginRight:'10px',marginTop:'8px'}} class="fa fa-phone" aria-hidden="true">
            </i><h5>Call Us:+919711068222 (gurugram)</h5></a>
            <a style={{display:"flex",flexDirection:"row",marginRight:'15px',color:'white'}} href="tel:+919711056222"><i style={{fontSize:"25px",marginRight:'10px',marginTop:'8px'}} class="fa fa-phone" aria-hidden="true">
            </i><h5>Call Us:+919711056222 (banglore)</h5></a>
            <a style={{display:"flex",flexDirection:"row",marginRight:'15px',color:'white'}} href="tel:+919812439504"><i style={{fontSize:"25px",marginRight:'10px',marginTop:'8px'}} class="fa fa-phone" aria-hidden="true">
            </i><h5>Customer Care:+919812439504</h5></a>
            <a
        href="https://wa.me/+919711068222"
        style={{display:"flex",flexDirection:"row",color:'white'}}
        // class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i style={{fontSize:"25px",marginRight:'10px',marginTop:'8px'}} class="fa fa-whatsapp"></i>
        <h5>+919711068222</h5>
      </a></div>
      
              </div>
            </div>
          </div>
          
          
        </div>
        
      </div>
    );
  }


export default Contact
