import React, { Component,useEffect } from "react";
import {motion,useViewportScroll, useTransform} from 'framer-motion'
import Aos from "aos";

const Gallery=()=>{

useEffect(() => {
  Aos.init({
    duration:1000
  })
}, [])
   
    return (
      <div id="portfolio" className="text-center">
        <motion.div className="container ">
       
          <div className="section-title">
            <h2 data-aos="fade-right">Gallery</h2>
            {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
              dapibus leonec.
            </p> */}
          </div>
          <div className="row">
            <div className="portfolio-items">
              <motion.div whileHover={{scale:1.1,zIndex:100}}  className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    {/* <a
                      href="img/portfolio/01-large.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    > */}
                      <div className="hover-text">
                        {/* <h4>Lorem Ipsum</h4> */}
                      </div>
                      <img
                        src="img/portfolio/MP11.jpg"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    {/* </a>{" "} */}
                  </div>
                </div>
              </motion.div>
              <motion.div whileHover={{scale:1.1,zIndex:100}} className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    {/* <a
                      href="img/portfolio/02-large.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    > */}
                      <div className="hover-text">
                        {/* <h4>Adipiscing Elit</h4> */}
                      </div>
                      <img
                        src="img/portfolio/MP2.jpeg"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    {/* </a>{" "} */}
                  </div>
                </div>
              </motion.div>
              <motion.div whileHover={{scale:1.1,zIndex:100}} className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    {/* <a
                      href="img/portfolio/03-large.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    > */}
                      <div className="hover-text">
                        {/* <h4>Lorem Ipsum</h4> */}
                      </div>
                      <img
                        src="img/portfolio/MP3.jpeg"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    {/* </a>{" "} */}
                  </div>
                </div>
              </motion.div>
              <motion.div whileHover={{scale:1.1,zIndex:100}} className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    {/* <a
                      href="img/portfolio/04-large.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    > */}
                      <div className="hover-text">
                        {/* <h4>Lorem Ipsum</h4> */}
                      </div>
                      <img
                        src="img/portfolio/MP4.jpeg"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    {/* </a>{" "} */}
                  </div>
                </div>
              </motion.div>
              <motion.div whileHover={{scale:1.1,zIndex:100}} className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    {/* <a
                      href="img/portfolio/05-large.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    > */}
                      <div className="hover-text">
                        {/* <h4>Adipiscing Elit</h4> */}
                      </div>
                      <img
                        src="img/portfolio/MP5.jpeg"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    {/* </a>{" "} */}
                  </div>
                </div>
              </motion.div>
              {/* <motion.div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/06-large.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Dolor Sit</h4>
                      </div>
                      <img
                        src="img/portfolio/mP6N.jpg"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div> */}
              {/* <motion.div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/07-large.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Dolor Sit</h4>
                      </div>
                      <img
                        src="img/portfolio/07-small.jpg"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <motion.div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/08-large.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Lorem Ipsum</h4>
                      </div>
                      <img
                        src="img/portfolio/08-small.jpg"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <motion.div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/09-large.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>Adipiscing Elit</h4>
                      </div>
                      <img
                        src="img/portfolio/09-small.jpg"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </motion.div>
      </div>
    );
  }


export default Gallery;
