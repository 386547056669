import React, {useEffect} from "react";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
import 'aos/dist/aos.css'
import Aos from "aos";
const Features=(props)=> {
   const animation=useAnimation()
    const {ref,inView}=useInView({
      threshold:0.2
    })
    useEffect(() => {
      Aos.init({
        duration:500
      })
    // if(inView){
    //   animation.start({
    //     x:0,
    //     transition:{
    //       type:'spring',duration:1,bounce:0.3
    //     }
    //   })
    // }
    //   if(!inView){
    //     animation.start({
    //       x:'-100vw'
    //     })
    //   }
    },[])
    return (
      <div id="features" className="text-center" >
        <div className="container">
          <div ref={ref} className="col-md-10 col-md-offset-1 section-title">
          <h2 data-aos="fade-right">How we Work</h2>           
          </div>
          
          <div data-aos="flip-right" className="row">
        
            {props.data
              ? props.data.map((d,i) => (
                  <div  key={`${d.title}-${i}`} className="col-xs-6 col-md-3">
                    {" "}
                    <i className={d.icon}></i>
                    <h3>{d.title}</h3>
                    <p>{d.text}</p>
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    );
  }


export default Features;
