import React, { Component ,useEffect} from "react";
import Top from './toppart'
import { motion } from "framer-motion";
import Aos from "aos";

const Header=(props)=>{ 
  useEffect(() => {
   Aos.init({
     duration:1000
   })
  }, [])
    return (
      <header id="header" style={{width:'100vw'}}>
        <Top></Top>
        <motion.div className="intro">
        
          <div className="overlay">
            <div className="container">
              <div className="row">
                
                <div   className="col-md-8 col-md-offset-2 intro-text">
                  <h2 data-aos="zoom-in">
                    {props.data ? props.data.title : "Loading"}
                    <span></span>
                  </h2>
                  <p data-aos="zoom-in">
                    {props.data ? props.data.paragraph : "Loading"}
                  </p>
                  <a
                    href="#features"
                    className="btn btn-custom btn-lg page-scroll"
                    data-aos="zoom-in"
                  >
                    Learn More
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </header>
    );
  }


export default Header;
