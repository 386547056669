import React, { Component,useEffect } from 'react'
import Aos from "aos";

const About=(props)=> {
useEffect(() => {
 Aos.init({
   duration:1000
 })
}, [])
    return (
        <div id="about">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6"> <img data-aos="zoom-in-up" src="img/aboutus2.jpg" className="img-responsive" alt=""/> </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
              
                <h2 data-aos="fade-right">About Us</h2>
                <p data-aos="zoom-in-up">{props.data ? props.data.paragraph : 'loading...'}</p>
                <h3 data-aos="fade-right">Why Choose Us?</h3>
                <div className="list-style">
                  <div data-aos="zoom-in-up" className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {props.data ? props.data.Why.map((d, i) => <li  key={`${d}-${i}`}>{d}</li>) : 'loading'}
                    </ul>
                  </div>
                  <div data-aos="zoom-in-up" className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                    {props.data ? props.data.Why2.map((d, i) => <li  key={`${d}-${i}`}> {d}</li>) : 'loading'}

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }


export default About
