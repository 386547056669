import React, { Component } from "react";
import { Link } from "react-router-dom";
import Map from './Maps'
// import { Map, GoogleApiWrapper,Marker } from 'react-google-maps';

export class Contact extends Component {
   
  render() {
    return (
      <div>
        <div id="contact">
          <div className="container">
          
            <div className="col-md-12">
              <div className="row">
                <div className='bottom_main'> 
                    <div className='bottom_content'>
                      <h1 >Contact Us</h1>
                      <hr/>
                      <div></div>
                      <div style={{width:'fit-content',margin:'auto'}}><span>
                    <i className="fa fa-map-marker"></i> Address :
                  </span> K1.81 new palm vihar sector 110 Gurugram Haryana pin 122017</div><br></br>
                      <div><span>
                    <i className="fa fa-phone"></i> Phone
                  </span> : Sunil Sangwan :+91 9711068222 || Customer-care:+91 9812439504</div><br></br>
                      <div></div>
                      <div><span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span> : crowncargo2021@gmail.com</div>
                      <hr/>
                      {/* <div>We are Gurgaon based Packing and moving company. We provide all shifting services from Delhi NCR to all over India at very affordable prices.</div> */}
                    </div>
                    <div className='bottom_content'>
                   <h1>Find us</h1>
                    <hr/>
                    <div style={{ textAlign:'center'}}><Map/></div>
                    </div>
                </div>
                <div className="social">
                  <ul>
                    <li>
                      <a
                        href={this.props.data ? this.props.data.facebook : "/"}
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href={this.props.data ? this.props.data.twitter : "/"}>
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href={this.props.data ? this.props.data.youtube : "/"}>
                        <i className="fa fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          </div>
        <div id="footer">
          <div className="container text-center">
            <p>
              &copy; 2021, All rights reserved Crown Cargo Movers & Packers.
             
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact
