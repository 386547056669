import React from 'react'
import Navigation from '../components/navigation'
import Contact from '../components/contact'
import './household.css'
import Contact2 from '../components/contact2'
import Maps from '../components/Maps'
function Household(props) {
    return (
      
      <div id="about">
      <div className="container">
        <div className="row">
        <div className="col-xs-12 col-md-6"> <img src="https://www.edumovlive.com/wp-content/uploads/2017/06/Packers-Movers.jpg" className="img-responsive" alt=""/> </div>
            
         
        <div className="col-xs-12 col-md-6"> 
              <div className="about-text">
                <p>
             Moving to a new home can be an additional difficult experience for youngsters to adapt to. 
         The actual distance moved is not so important. Either across state/Cities or across the country, 
         the transition is very stressful because it needs children to disconnect the attachments they have 
         formed with their most intimate physical environments; the spaces inside the only home they have known. Moves involving a larger 
         distances, or which require children to change their schools, leave behind their close friends and family, 
         or leave behind the comfort zone of their familiarity with their old community are more tense-full than simple moves within 
         a neighbourhood, but however you slice it, moves are stressful.
        We are providing a top class Household Packing Services. These services are accomplished by the team of experts who has affluent
        industry proficiency and practice. Crown Cargo Packers and Movers have the distinction of being the pioneer and leader in the home 
        shifting segment in the country with its flagship company, Crown Cargo Packers and Movers. Industry pioneer, Crown Cargo Packers and Movers 
        has set the industry benchmarks with its innovative concepts of Portable Home and Perfect Box, which have greatly enhanced the safety, 
        functionality and economy of moving house.
        </p></div>
        </div>
        </div>
        </div>
        <Contact data={props.data}/>
        <div className="container">
          <div className="col-md-12">
            <div className="row">
        <div className='bottom_content'>
                   <h1>Find us</h1>
                    <hr/>
                    <div style={{ textAlign:'center'}}><Maps/></div>
                    </div>
                    </div>
                    </div>
                    </div>
                    <div id="footer">
          <div className="container text-center">
            <p>
              &copy; 2021, All rights reserved Crown Cargo Movers & Packers.
             
            </p>
          </div>
        </div>
        </div>
        
    )
}

export default Household
